import { Component } from '@angular/core';

@Component({
  selector: 'app-venstremenu',
  templateUrl: './venstremenu.component.html',
  styleUrls: ['./venstremenu.component.scss']
})
export class VenstremenuComponent
{
    selected: any;

    constructor()
    {
    }
}
