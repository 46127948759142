import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LektionService } from '../../../../../services/lektion.service';


@Component({
  selector: 'app-question-dialog',
  styleUrls: ['./dialog.scss'],
  template: `
    <h1 mat-dialog-title>Spørgsmål</h1>
    <div mat-dialog-content class="content">
      <mat-form-field>
        <textarea
          placeholder="Spørgsmål"
          matInput
          [(ngModel)]="data.svar.svar"
        ></textarea>
      </mat-form-field>
      <br />
      <mat-slide-toggle [(ngModel)]="data.svar.korrektsvar" color="accent">Korrekt svar?</mat-slide-toggle>
<br />
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        [(ngModel)]="data.svar.label"
      >
        <mat-button-toggle *ngFor="let opt of labelOptions" [value]="opt">
          <mat-icon [ngClass]="opt">{{
            opt === 'gray' ? 'check_circle' : 'lens'
          }}</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="data" cdkFocusInitial>
        {{ data.isNew ? 'Add Question' : 'Update Question' }}
      </button>

      <app-delete-button
        (delete)="handleSvarDelete()"
        *ngIf="!data.isNew"
      ></app-delete-button>
    </div>
  `
})
export class QuestionDialogComponent {
  labelOptions = ['purple', 'blue', 'green', 'yellow', 'red', 'gray'];

  constructor(
    public dialogRef: MatDialogRef<QuestionDialogComponent>,
    private boardService: LektionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleSvarDelete() {
    this.boardService.removeSvar(this.data.boardId, this.data.svar);
    this.dialogRef.close();
  }
}
