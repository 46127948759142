import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-huskespil',
  templateUrl: './huskespil.component.html',
  styleUrls: ['./huskespil.component.scss']
})
export class HuskespilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
